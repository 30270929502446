<template>
  <div>
    <div class="s-p-3 overflow-x-hidden">
      <b-carousel ref="rewardsCardCarousel" v-model="slide" :interval="0" :indicators="cards.length > 1" :no-wrap="true"
        class="reward-card__carousel mb-4">
        <b-carousel-slide v-for="card in cards" :key="card.id">
          <template #img><b-card class="border border-1 reward-card" no-body>
              <div class="reward-card__header px-3 d-flex justify-content-between align-items-center">
                <div class="reward-card__title">Les Mills 集點活動</div>
                <div class="d-flex align-items-center">
                  <span class="reward-card__header__point">{{ availablePoints }}</span>
                  <span class="small ml-2">點</span>
                </div>
              </div>

              <div class="reward-card__body p-4">
                <div v-for="i in 10" :key="i" class="reward-card__body__point" :class="i <= card.point ? 'active' : ''">
                  <div class="reward-card__body__point__stamp d-flex justify-content-center align-items-center">
                    <img v-if="i <= card.point" src="./images/stamp.svg" />
                    <span v-else>{{ i }}</span>
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </b-carousel-slide>
        <button v-if="cards.length > 1" :disabled="slide === 0" href="#" role="button" class="carousel-control-prev"
          @click="prev">
          <span class="carousel-control-prev-icon"></span>
          <span class="sr-only">Next slide</span>
        </button>
        <button v-if="cards.length > 1" :disabled="slide === cards.length - 1" href="#" role="button"
          class="carousel-control-next" @click="next">
          <span class="carousel-control-next-icon"></span>
          <span class="sr-only">Next slide</span>
        </button>
      </b-carousel>

      <div class="reward-award mb-3">
        <div class="reward-award__title">點數獎項</div>

        <b-card v-for="gift in gifts" :key="gift.id" class="reward-award-item d-flex flex-nowrap flex-row mt-3" no-body>
          <div class="reward-award-item__image">
            <img class="img-fluid" :src="gift.image" />
          </div>
          <div class="flex-fill p-2 d-flex flex-column">
            <div class="reward-award-item__title flex-fill">{{ gift.name }}</div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="reward-award-item__point d-flex align-items-center">
                <div class="reward-award-item__stamp mr-2">
                  <img src="./images/stamp.svg" />
                </div>
                <div>{{ gift.point }}</div>
              </div>

              <button v-if="gift.quantity <= 0"
                class="s-btn s-liff-theme-btn-outline s-btn-xs reward-award-item__button"
                :disabled="gift.quantity <= 0">已閱換完畢</button>
              <button v-else-if="gift.point > point"
                class="s-btn s-liff-theme-btn-outline s-btn-xs reward-award-item__button"
                :disabled="gift.point > point">點數不足</button>
              <button v-else class="s-btn s-liff-theme-btn-outline s-btn-xs reward-award-item__button"
                @click="() => handleExchange(gift)">兌換</button>
            </div>
          </div>
        </b-card>
      </div>

      <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
      <Loading v-if="fetching" />
      <Popup ref="popupUsage">
        <div slot="title">
          是否確定要使用
          <span class="s-primary">{{ popup.point }}</span>
          點
        </div>
        <div class="s-mt-5 s-mb-5 s-text-center">
          請注意，點數一經使用即無法取消
        </div>
        <div slot="actions" class="s-flex s-space-x-2">
          <button class="s-btn s-liff-theme-btn-outline s-btn-sm" type="button" @click="$refs.popupUsage.close()">
            取消
          </button>
          <button class="s-btn s-liff-theme-btn s-btn-sm" type="button" @click="handleChooseUsage">
            確定
          </button>
        </div>
      </Popup>
    </div>

    <div class="reward-notice s-p-3">
      <div class="reward-notice__title">注意事項</div>
      <ol>
        <li>
          若您於4/20前至直營門市消費，尚未升級會員等級，請主動與門市人員聯絡，由門市人員協助處理。
        </li>
        <li>
          每位會員每場體驗活動僅限報名一次無法重複報名。會員已詳讀並同意注意事項。
        </li>
        <li>
          填寫報名資料請務必確認您的資訊是否正確，以便錄取後通知．送出後不得進行任何資料更改；請注意有無開啟阻擋廣告功能。
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PointPagination from '@/pages/Liff/Point/PointPagination';
import pointApi from '@/apis/liff/v2/point';
import Popup from '@/components/Page/Liff/Shared/Popup';
import Loading from '@/components/Page/Liff/Shared/Loading'

export default {
  components: {
    Popup,
    PointPagination,
    Loading,
  },
  data() {
    return {
      slide: 0,
      currentGift: null,
      gifts: [],
      popup: {
        point: 0,
      },
      eventCode: this.$route.query.event ?? null,
      fetching: false,
      page: 1,
      totalPage: 1,
    };
  },
  computed: {
    ...mapGetters('liffPoint', [
      'availablePoints',
    ]),
    formattedAvailablePoints() {
      return this.availablePoints?.point?.toLocaleString('en-US');
    },
    point() {
      return typeof this.availablePoints === 'number' ? this.availablePoints : 0
    },
    cards() {
      return this.point > 0
        ? Array.from({ length: Math.ceil(this.point / 10) }, (_, i) => ({
          id: i,
          point: i < Math.floor(this.point / 10) ? 10 : this.point % 10,
        }))
        : [0];
    }
  },
  watch: {
    page() {
      this.fetchGifts()
    }
  },
  mounted() {
    this.fetchAvailablePoints({ eventCode: this.eventCode });
    this.fetchGifts();
  },
  methods: {
    ...mapActions('liffPoint', [
      'fetchAvailablePoints',
      'fetchExpiringSoonPoints',
    ]),
    async fetchGifts() {
      this.fetching = true
      try {
        let res = await pointApi.listGifts(this.eventCode, this.page);
        let gifts = res.data
        this.totalPage = res.meta.last_page

        if (!gifts || !gifts.length) {
          this.gifts = [];
          return;
        }

        this.gifts = gifts.map((g) => ({
          id: g.id,
          name: g.name,
          subtitle: g.data?.sub_title || '',
          badge: g.data?.badge || '',
          point: g.point_need,
          image: g.image_url || '',
          quantity: g.quantity,
        }));
      } catch (error) {
        console.error(error)
      }
      finally {
        this.fetching = false
      }

    },
    handleExchange(gift) {
      this.popup.point = (gift.point ?? 0).toLocaleString('en-US');
      this.currentGift = gift;
      this.$refs.popupUsage.open();
    },
    async handleChooseUsage() {
      try {
        await pointApi.redeemGift(this.eventCode, this.currentGift)
        this.$swal({
          title: '兌換完成！',
          icon: 'success',
          button: '確定',
        }).then(() => {
          this.fetchGifts()
          this.$refs.popupUsage.close();
        })
      } catch (error) {
        console.log(error)
      }
      this.fetchAvailablePoints({ eventCode: this.eventCode });
      this.fetchExpiringSoonPoints({ eventCode: this.eventCode });
    },
    next() {
      this.$refs.rewardsCardCarousel.next();
    },
    prev() {
      this.$refs.rewardsCardCarousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.reward-card {
  border-radius: 0.5rem;
  overflow-x: hidden;

  ::v-deep &__carousel {

    //min-height: 260px;
    //overflow: hidden;
    .carousel-inner {
      overflow: visible;
    }

    .carousel-caption {
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
    }

    .carousel-item {
      // min-height: 260px;
      margin-right: -100% !important;
    }

    .carousel-control-prev,
    .carousel-control-next {
      z-index: 10;
      padding: 5px !important;
      width: auto;
      box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
      background: #ffffff;
      border: 1px solid #e5e5ea;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      top: 55%;
      bottom: auto;
      opacity: 1;
      transform: translateY(-50%);

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    .carousel-control-prev {
      left: -8px;
    }

    .carousel-control-next {
      right: -8px;
    }

    .carousel-control-next-icon {
      transform: translateX(1px);
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.69434 7.99707C8.69434 8.22559 8.60645 8.43652 8.43066 8.6123L1.87402 15.0195C1.71582 15.1777 1.51367 15.2656 1.28516 15.2656C0.819336 15.2656 0.458984 14.9053 0.458984 14.4307C0.458984 14.2021 0.546875 14 0.696289 13.8418L6.68164 7.99707L0.696289 2.14355C0.546875 1.99414 0.458984 1.7832 0.458984 1.55469C0.458984 1.08887 0.819336 0.728516 1.28516 0.728516C1.51367 0.728516 1.71582 0.816406 1.87402 0.974609L8.43066 7.38184C8.60645 7.55762 8.69434 7.75977 8.69434 7.99707Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 9px 16px;
    }

    .carousel-control-prev-icon {
      transform: translateX(-1px);
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.305664 7.99707C0.305664 7.75977 0.402344 7.55762 0.578125 7.38184L7.13477 0.974609C7.28418 0.816406 7.48633 0.728516 7.71484 0.728516C8.18945 0.728516 8.5498 1.08887 8.5498 1.55469C8.5498 1.7832 8.45312 1.99414 8.30371 2.14355L2.31836 7.99707L8.30371 13.8418C8.45312 14 8.5498 14.2021 8.5498 14.4307C8.5498 14.9053 8.18945 15.2656 7.71484 15.2656C7.48633 15.2656 7.28418 15.1777 7.13477 15.0195L0.578125 8.6123C0.402344 8.43652 0.305664 8.22559 0.305664 7.99707Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 9px 16px;
    }

    .carousel-indicators {
      overflow: auto;
      bottom: -2rem;

      >li {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        background-color: var(--s-primary);
        border-radius: 100%;
      }
    }
  }

  &__header {
    border-top-color: var(--s-primary) !important;
    border-top-width: 5px !important;
    font-weight: 600;
    border-bottom: solid 1px #e5e5ea;

    &__point {
      font-size: 30px;
      color: var(--s-primary);
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem 1rem;

    &__point {
      opacity: 0.5;

      &__stamp {
        aspect-ratio: 1/1;
        border: dashed 2px #c0c4cc;
        background-color: #f2f2f7;
        color: #979797;
        border-radius: 100%;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
      }

      &__date {
        font-size: 12px;
        text-align: center;
        color: #979797;
        visibility: hidden;
      }
    }

    &__point.active {
      opacity: 1;

      .reward-card__body__point__stamp {
        //content: url(images/stamp.svg);
        margin-bottom: 20px;
        background-color: var(--s-primary);
        border: none;
        box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
        padding: 22%;
      }

      .reward-card__body__point__date {
        visibility: visible;
      }
    }

    //     &__point {
    //       &.active {
    //         content: '<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M14 2L17.708 9.512L26 10.724L20 16.568L21.416 24.824L14 20.924L6.584 24.824L8 16.568L2 10.724L10.292 9.512L14 2Z" fill="white" stroke="#FBFBFB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>';
    //       }
    //     }
  }
}

.reward-award {
  &__title {
    font-weight: 600;
    font-size: 16px;
  }

  &-item {
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: solid 1px #e5e5ea;

    &__image {
      aspect-ratio: 1/1;
      overflow: hidden;
      max-width: 35%;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
    }

    &__point {
      font-size: 20px;
      font-weight: 600;
      color: var(--s-primary);
    }

    &__stamp {
      background-color: var(--s-primary);
      border-radius: 1rem;
      display: inline-block;
      padding: 5px;
      aspect-ratio: 1/1;
      line-height: 0;

      img {
        width: 10px;
        height: 10px;
      }
    }

    &__button {
      font-size: 13px;
      line-height: 18px;
      padding: 6px 16px;
      font-weight: 600;
      width: auto;

      &:disabled {
        border: solid 1px #c0c4cc !important;
        background-color: #e5e5ea;
        color: #2c2c2e !important;
        opacity: 1;
      }
    }
  }
}

.reward-notice {
  border-top: solid 1px #e5e5ea;
  font-size: 14px;
  font-weight: 400;
  color: #636366;

  &__title {
    font-weight: 600;
    font-size: 16px;
  }

  ol {
    list-style: decimal;
    padding: inherit;

    li {
      margin-bottom: 0.5rem;
    }

    li::marker {
      color: var(--s-primary);
      font-size: 600;
    }
  }
}
</style>
